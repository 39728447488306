import Debug from 'debug';

import { ItemTypes } from '@theorchard/songwhip-api';

import getUserPagesApi from '~/app/lib/songwhipApi/users/getUserPagesApi';
import getDeviceTimezone from '~/app/lib/utils/getDeviceTimezone';
import measure from '~/app/lib/utils/measure';
import doUntil from '~/app/lib/utils/doUntil';

import {
  getAccountAnalyticsApi,
  GetAccountAnalyticsApiParams,
} from '~/app/lib/songwhipApi/accounts';

import { toTimestamp } from '../../utils/date';
import { Dispatch, State } from '../types';

import {
  MappedAlbum,
  MappedArtist,
  MappedCustomPage,
  MappedTrack,
} from '../../songwhipApi/mapper';

import { DashboardActionTypes } from './types';

const debug = Debug('songwhip/dashboard/actions');

export const fetchUserPages =
  (userId: number) => async (dispatch: Dispatch) => {
    dispatch({
      type: DashboardActionTypes.FETCH_PAGES_START,
    });

    try {
      const items = await measure('fetch user pages', () =>
        getUserPagesApi({ userId })
      );

      dispatch({
        items,
        type: DashboardActionTypes.FETCH_PAGES_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: DashboardActionTypes.FETCH_PAGES_ERROR,
        error,
      });
    }
  };

export const fetchDashboardAnalytics =
  (params: GetAccountAnalyticsApiParams) => async (dispatch: Dispatch) => {
    const { accountId } = params;
    const timezone = getDeviceTimezone();

    await doUntil({
      maxAttempts: 5,
      timeBetweenAttempts: 6000,

      task: async () => {
        try {
          dispatch({
            type: DashboardActionTypes.FETCH_ANALYTICS_START,
            timestamp: Date.now(),
            accountId,
          });

          const payload = await getAccountAnalyticsApi({
            ...params,
            timezone,
          });

          dispatch({
            type: DashboardActionTypes.FETCH_ANALYTICS_SUCCESS,
            payload,
            accountId,
          });

          // if analytics data is 'stale' then return false to indicate retry needed
          if (payload.isStale) {
            debug('analytics stale, will refetch');
            return false;
          }

          // if data is fresh we can stop trying
          debug('analytics data is fresh');
          return true;
        } catch (error) {
          dispatch({
            type: DashboardActionTypes.FETCH_ANALYTICS_ERROR,
            accountId,
            error,
          });

          // fetch error, return false to stop trying
          return true;
        }
      },
    });
  };

export const updateDashboardItem =
  (item: MappedArtist | MappedAlbum | MappedTrack | MappedCustomPage) =>
  async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const items = state.dashboard?.items.value;

    if (!items) return;

    switch (item.type) {
      // NOTE: We only update albums as we do need to reflect changes in the dashboard
      // for prerelease pages (upc, releaseDate, etc) to avoid any confusion for users.
      // For other page types we can only have stale image that should not be an issue.
      case ItemTypes.ALBUM: {
        const currentAlbumIndex = items.findIndex(
          ({ id, type }) => id === item.id && type === ItemTypes.ALBUM
        );

        const currentAlbum = items[currentAlbumIndex];
        if (!currentAlbum || currentAlbum.type !== ItemTypes.ALBUM) return;

        items.splice(currentAlbumIndex, 1, {
          ...currentAlbum,
          name: item.name,
          upc: item.upc,
          isPrerelease: item.isPrerelease,
          isDraft: item.isDraft,
          releaseDate:
            'releaseDate' in item ? toTimestamp(item.releaseDate) : undefined,
        });

        dispatch({
          type: DashboardActionTypes.FETCH_PAGES_SUCCESS,
          items,
        });

        break;
      }
    }
  };

import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';
import { toQueryString } from '~/app/lib/router2';

import mapCustomPage from '../mapper/mapCustomPage';
import mapArtist from '../mapper/mapArtist';
import mapAlbum from '../mapper/mapAlbum';
import mapTrack from '../mapper/mapTrack';
import songwhipApi from '../songwhipApi';

import {
  SongwhipAlbum,
  SongwhipArtist,
  SongwhipCustomPage,
  SongwhipTrack,
} from '../types';

import { mapAccount } from './lib/mapAccount';
import { AccountAnalytics } from './types';

export const getAccountApi = async (accountId: number) => {
  const {
    json: {
      data: { account },
    },
  } = await songwhipApi(`accounts/${accountId}`);

  return mapAccount(account);
};

export const getAccountPagesApi = async ({
  accountId,
}: {
  accountId: number;
}) => {
  const {
    json: {
      data: { artists, albums, tracks, customPages },
    },
  } = (await songwhipApi(`accounts/${accountId}/pages`)) as {
    json: {
      data: {
        artists?: SongwhipArtist[];
        albums?: SongwhipAlbum[];
        tracks?: SongwhipTrack[];
        customPages?: SongwhipCustomPage[];
      };
    };
  };

  return {
    artists: artists?.map(mapArtist),
    albums: albums?.map(mapAlbum),
    tracks: tracks?.map(mapTrack),
    customPages: customPages?.map(mapCustomPage),
  };
};

export interface GetAccountAnalyticsApiParams {
  accountId: number;
  artistIds?: number[];
  albumIds?: number[];
  trackIds?: number[];
  customPageIds?: number[];
  days: number;
  timezone?: string;
}

export interface GetAccountAnalyticsApiPayload {
  isStale: boolean;
  data: {
    table: string;
    dateFrom: string;
    dateTo: string;
    results: AccountAnalytics;
  };
}

export const getAccountAnalyticsApi = async ({
  accountId,
  artistIds,
  albumIds,
  trackIds,
  customPageIds,
  days,
  timezone,
}: GetAccountAnalyticsApiParams) => {
  return (
    await songwhipApi(
      `accounts/${accountId}/analytics?${toQueryString(
        removeUndefinedKeys({
          // sort ids so url doesn't change for cache key consistency
          artistIds: artistIds?.sort().join(','),
          albumIds: albumIds?.sort().join(','),
          trackIds: trackIds?.sort().join(','),
          customPageIds: customPageIds?.sort().join(','),
          timezone,
          days,
        })
      )}`
    )
  ).json as GetAccountAnalyticsApiPayload;
};

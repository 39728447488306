import wait from './wait';

/**
 * Run a task until a condition is true
 */
const doUntil = async ({
  task,
  maxAttempts,
  timeBetweenAttempts = 1000,
}: {
  task: () => Promise<boolean>;
  maxAttempts: number;
  timeBetweenAttempts?: number;
}) => {
  while (maxAttempts--) {
    const result = await task();

    // truthy result means success and we can stop trying
    if (result) return;

    // wait until trying again
    await wait(timeBetweenAttempts);

    // wait a bit longer each time (exponential back-off)
    timeBetweenAttempts *= 1.5;
  }
};

export default doUntil;

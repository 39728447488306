import { GetAccountAnalyticsApiPayload } from '~/app/lib/songwhipApi/accounts';
import { PartialItem } from '~/app/lib/songwhipApi/users/getUserPagesApi';
import { AccountAnalytics } from '~/app/lib/songwhipApi/accounts/types';
import { StatusTypes, StoredError } from '../types';
import ApiError from '~/app/lib/errors/ApiError';

export type DashboardState = {
  items: {
    status?: StatusTypes;
    value?: PartialItem[];
    error?: StoredError;
  };

  analytics: {
    status?: StatusTypes;
    error?: ApiError;
    value?: AccountAnalytics;
    lastFetchedAt?: number;
    isStale?: boolean;
  };
};

export enum DashboardActionTypes {
  FETCH_PAGES_START = 'DASHBOARD_FETCH_PAGES_START',
  FETCH_PAGES_SUCCESS = 'DASHBOARD_FETCH_PAGES_SUCCESS',
  FETCH_PAGES_ERROR = 'DASHBOARD_FETCH_PAGES_ERROR',
  FETCH_ANALYTICS_START = 'DASHBOARD_FETCH_ANALYTICS_START',
  FETCH_ANALYTICS_SUCCESS = 'DASHBOARD_FETCH_ANALYTICS_SUCCESS',
  FETCH_ANALYTICS_ERROR = 'DASHBOARD_FETCH_ANALYTICS_ERROR',
  RESET = 'DASHBOARD_RESET',
}

export interface DashboardFetchPagesStartAction {
  type: DashboardActionTypes.FETCH_PAGES_START;
}

export interface DashboardFetchPagesSuccessAction {
  type: DashboardActionTypes.FETCH_PAGES_SUCCESS;
  items?: PartialItem[];
}

export interface DashboardFetchPagesErrorAction {
  type: DashboardActionTypes.FETCH_PAGES_ERROR;
  error: ApiError;
}

export interface DashboardFetchAnalyticsStartAction {
  type: DashboardActionTypes.FETCH_ANALYTICS_START;
  timestamp: number;
}

export interface DashboardFetchAnalyticsSuccessAction {
  type: DashboardActionTypes.FETCH_ANALYTICS_SUCCESS;
  payload: GetAccountAnalyticsApiPayload;
}

export interface DashboardFetchAnalyticsErrorAction {
  type: DashboardActionTypes.FETCH_ANALYTICS_ERROR;
  error: ApiError;
}

export interface DashboardResetAction {
  type: DashboardActionTypes.RESET;
}

export type DashboardAction =
  | DashboardFetchPagesStartAction
  | DashboardFetchPagesSuccessAction
  | DashboardFetchPagesErrorAction
  | DashboardFetchAnalyticsStartAction
  | DashboardFetchAnalyticsSuccessAction
  | DashboardFetchAnalyticsErrorAction
  | DashboardResetAction;
